import { GetServerSidePropsContext } from 'next';

import { IndexScene, IndexSceneProps } from '~/scenes/Index';
import { getServerSidePropsFor } from '~/scenes/_app/helpers';

function IndexPage(props: IndexSceneProps) {
  return <IndexScene {...props} />;
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return await getServerSidePropsFor(context, IndexPage);
}

export default IndexPage;
